<template>
  <div>
    <h1 class="page-title">Manage Sums</h1>

    <SimpleTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :expanded_headers="expanded_headers"
      :totalCount="totalCount"
      url_fragment="Sums"
      @hitPagination="fetchNextData"
      @add_item_click="add_item_click"
      @edit_item_click="edit_item_click"
    />
  </div>
</template>
<script>
import SimpleTable from "@/components/common/SimpleTable";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import { bus } from "@/main.js";

export default {
  name: "SumList",
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Taken",
          value: "is_taken",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Minimum",
          value: "minimum",
        },
        {
          text: "Maximum",
          value: "maximum",
        },
        {
          text: "Default",
          value: "default",
        },
        {
          text: "Step",
          value: "step",
        },
        {
          text: "Actions",
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
      items: [],
      expanded_headers: [
        { text: "Description", value: "description" },
        { text: "Basic Rate", value: "basic_rate" },
        { text: "Basic Percent", value: "basic_percent" },
        { text: "Threshold", value: "threshold_1_rate" },
        { text: "Threshold", value: "threshold_2_rate" },
        { text: "Threshold", value: "threshold_3_rate" },
      ],
      loading: false,
      crud_state: crud_state.LIST,
      totalCount: 0,
      paginations: {},
    };
  },
  components: {
    SimpleTable,
  },
  async mounted() {
    this.items = await this.get_items();
    bus.$on("edit_save", async () => {
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
      // refresh data
      this.items = await this.get_items();
    });
  },
  methods: {
    async fetchNextData(pagination) {
      this.items = await this.get_items(pagination);
    },
    async get_items(pagination = { itemsPerPage: 10, page: 1 }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("sums");
      let filters = { limit: limit, start: offset };

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        return res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    add_item_click() {
      this.crud_state = crud_state.NEW;
      this.$router.push({
        path: "/master-data/sum/add",
        query: {
          crud_state: this.crud_state,
        },
      });
    },
    edit_item_click(id) {
      if (id) {
        this.crud_state = crud_state.EDIT;
        this.$router.push({
          path: `/master-data/sum/edit/${id}`,
          query: {
            crud_state: this.crud_state,
          },
        });
      } else {
        this.edit_error("Invalid data passed");
      }
    },
  },
};
</script>
