<template>
  <div>
    <h1 class="page-title">Product Documents</h1>

    <SimpleTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :totalCount="totalCount"
      :hideExpand="true"
      url_fragment="product-docs"
      @hitPagination="fetchNextData"
      @add_item_click="add_item_click"
      @edit_item_click="edit_item_click"
    />
  </div>
</template>

<script>
import SimpleTable from "@/components/common/SimpleTable";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";

export default {
  name: "ProductDocList",

  data() {
    return {
      headers: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Rank",
          value: "rank",
        },
        {
          text: "Product Name",
          value: "product.name",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
        },
      ],
      items: [],
      loading: false,
      crud_state: crud_state.LIST,
      totalCount: 0,
      paginations: {},
    };
  },
  components: {
    SimpleTable,
  },
  async mounted() {
    this.items = await this.get_items();
  },
  methods: {
    async fetchNextData(pagination) {
      this.items = await this.get_items(pagination);
    },
    async get_items(pagination = { itemsPerPage: 10, page: 1 }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("product-docs");
      let filters = { limit: limit, start: offset };

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        return res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    add_item_click() {
      this.crud_state = crud_state.NEW;
      this.$router.push({
        path: "/master-data/product-docs/add",
        query: {
          crud_state: this.crud_state,
        },
      });
    },
    edit_item_click(id) {
      if (id) {
        this.crud_state = crud_state.EDIT;
        this.$router.push({
          path: `/master-data/product-docs/edit/${id}`,
          query: {
            crud_state: this.crud_state,
          },
        });
      } else {
        this.edit_error("Invalid data passed");
      }
    },
  },
};
</script>
